import React from "react";
import { SocialMenu } from "../DesktopMenu/SocialMenu/SocialMenu";
import { Logo } from "../Logo/Logo";
import MobileMainMenu from "./MobileMainMenu/MobileMainMenu";

const MobileMenu = ({ opened, setOpened, navigationLinks }) => {
  const handleToggleClick = () => setOpened(!opened);

  return (
    <div className="flex justify-between items-center w-full md:hidden relative z-[60]">
      <Logo />
      <div onClick={handleToggleClick}>
        <SocialMenu />
      </div>
      <div
        className={`tham tham-e-squeeze tham-w-10 md:hidden ${
          opened ? "tham-active" : ""
        }`}
        onClick={handleToggleClick}
      >
        <div className="tham-box">
          <div className="tham-inner bg-neutral-orange" />
        </div>
      </div>
      {opened && (
        <div className="fixed top-[100px] inset-0 bg-background-main bg-opacity-95 z-[50] flex overflow-y-auto flex-col items-start p-4 space-y-4">
          <MobileMainMenu
            navigationLinks={navigationLinks}
            setOpened={setOpened}
          />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
