import React from "react";

const BGMobile9 = () => {
  return (
    <svg
      width="166"
      height="196"
      viewBox="0 0 166 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute md:hidden top-[3340px] left-0"
    >
      <path
        d="M22.7989 67.0326C24.43 66.148 26.3972 66.1453 28.0306 67.0257L40.5545 73.7756L52.6819 81.2146C54.2636 82.1848 55.2494 83.8871 55.3037 85.7419L55.72 99.9629L55.3414 114.185C55.292 116.04 54.3107 117.745 52.7316 118.719L40.624 126.19L28.118 132.973C26.4869 133.858 24.5197 133.861 22.8863 132.98L10.3624 126.23L-1.76493 118.791C-3.34662 117.821 -4.33249 116.119 -4.38678 114.264L-4.80312 100.043L-4.42449 85.8211C-4.3751 83.9662 -3.39376 82.2612 -1.81464 81.2868L10.2929 73.8158L22.7989 67.0326Z"
        stroke="#FFB21C"
      />
      <path
        d="M-12.8951 1.59313C-11.1157 0.628018 -8.96965 0.625173 -7.18774 1.58556L5.34847 8.34213L17.4877 15.7884C19.2132 16.8468 20.2887 18.7039 20.3479 20.7273L20.7647 34.9622L20.3857 49.1982C20.3318 51.2218 19.2612 53.0817 17.5386 54.1447L5.4191 61.6231L-7.09916 68.4129C-8.87852 69.378 -11.0246 69.3808 -12.8065 68.4204L-25.3427 61.6639L-37.4819 54.2176C-39.2074 53.1592 -40.2829 51.3021 -40.3421 49.2787L-40.7589 35.0438L-40.3799 20.8078C-40.326 18.7842 -39.2554 16.9243 -37.5328 15.8613L-25.4133 8.38291L-12.8951 1.59313Z"
        fill="#FFB21C"
      />
      <path
        d="M57.1049 1.59313C58.8843 0.628018 61.0304 0.625173 62.8123 1.58556L75.3485 8.34213L87.4877 15.7884C89.2132 16.8468 90.2887 18.7039 90.3479 20.7273L90.7647 34.9622L90.3857 49.1982C90.3318 51.2218 89.2612 53.0817 87.5386 54.1447L75.4191 61.6231L62.9008 68.4129C61.1215 69.378 58.9754 69.3808 57.1935 68.4204L44.6573 61.6639L32.5181 54.2176C30.7926 53.1592 29.7171 51.3021 29.6579 49.2787L29.2411 35.0438L29.6201 20.8078C29.674 18.7842 30.7446 16.9243 32.4672 15.8613L44.5867 8.38291L57.1049 1.59313Z"
        fill="#FFB21C"
      />
      <path
        d="M128.343 6.03264C129.974 5.14796 131.942 5.14535 133.575 6.0257L146.099 12.7756L158.226 20.2146C159.808 21.1848 160.794 22.8871 160.848 24.7419L161.264 38.9629L160.886 53.1849C160.836 55.0398 159.855 56.7448 158.276 57.7192L146.168 65.1902L133.662 71.9734C132.031 72.858 130.064 72.8607 128.431 71.9803L115.907 65.2303L103.78 57.7914C102.198 56.8212 101.212 55.1189 101.158 53.2641L100.741 39.0431L101.12 24.8211C101.169 22.9662 102.151 21.2612 103.73 20.2868L115.837 12.8158L128.343 6.03264Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BGMobile9;
