import React from "react";

const textStyles = {
  h1_main:
    "font-BenzinBold font-normal tracking-widest text-[53px] md:text-8xl text-neutral-orange",
  h2_main:
    "font-BenzinBold font-normal tracking-widest text-[32px] md:text-7xl text-neutral-orange",
  h2_white:
    "font-BenzinBold font-normal tracking-widest text-[32px] md:text-7xl text-neutral-white",
  h3_white:
    "font-BenzinBold font-normal tracking-widest text-[18px] md:text-5xl text-neutral-white",
};

const Heading = ({ type = "h1_main", children, className = "", ...props }) => {
  const textStyle = textStyles[type];

  return (
    <p
      className={`${textStyle} text-${type} tracking-tighter ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};

export default Heading;
