import React from "react";

const BGMobile2 = () => {
  return (
    <svg
      width="67"
      height="191"
      viewBox="0 0 67 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute md:hidden top-[320px] right-0"
    >
      <path
        d="M32.3433 62.3447C33.9744 61.46 35.9416 61.4574 37.575 62.3377L50.0989 69.0876L62.2263 76.5266C63.808 77.4968 64.7938 79.1991 64.8481 81.0539L65.2645 95.2749L64.8858 109.497C64.8365 111.352 63.8551 113.057 62.276 114.031L50.1684 121.502L37.6625 128.285C36.0314 129.17 34.0642 129.173 32.4308 128.292L19.9068 121.542L7.77951 114.103C6.19781 113.133 5.21195 111.431 5.15765 109.576L4.74132 95.3551L5.11995 81.1331C5.16933 79.2782 6.15067 77.5732 7.72979 76.5988L19.8374 69.1278L32.3433 62.3447Z"
        stroke="#FFB21C"
      />
      <path
        d="M66.3433 2.03264C67.9744 1.14796 69.9416 1.14535 71.575 2.0257L84.0989 8.77564L96.2263 16.2146C97.808 17.1848 98.7938 18.8871 98.8481 20.7419L99.2645 34.9629L98.8858 49.1849C98.8365 51.0398 97.8551 52.7448 96.276 53.7192L84.1684 61.1902L71.6625 67.9734C70.0314 68.858 68.0642 68.8607 66.4308 67.9803L53.9068 61.2303L41.7795 53.7914C40.1978 52.8212 39.2119 51.1189 39.1576 49.2641L38.7413 35.0431L39.1199 20.8211C39.1693 18.9662 40.1507 17.2612 41.7298 16.2868L53.8374 8.81576L66.3433 2.03264Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BGMobile2;
