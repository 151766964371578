import React from "react";
import { Logo } from "../Logo/Logo";
import MainMenu from "./MainMenu/MainMenu";
import { SocialMenu } from "./SocialMenu/SocialMenu";

const DesktopMenu = ({ navigationLinks }) => {
  return (
    <div className="hidden md:flex items-center justify-between h-16">
      <Logo />
      <MainMenu navigationLinks={navigationLinks} />
      <SocialMenu />
    </div>
  );
};

export default DesktopMenu;
