import React from "react";
import TwitterIcon from "../../../../assets/svg/TwitterIcon";
import TgIcon from "../../../../assets/svg/TgIcon";

export const SocialMenu = () => {
  return (
    <div className="flex gap-5 items-center">
      <a
        href="https://twitter.com/nctr_solana"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
      </a>
      <a href="https://t.me/nctr_group" target="_blank" rel="noreferrer">
        <TgIcon />
      </a>
    </div>
  );
};
