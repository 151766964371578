import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_SPACE_ID,
} from "../constant/constant";

const query = `
        {
            utilitiesCollection {
                items {
                sys {
                    id
                }
                __typename
                number
                title
                description
                url
                }
            }
        }
`;

// Fuinction to fetch data from Contentful
export const getUtilities = async () => {
  const response = await fetch(
    `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    }
  );
  const data = await response.json();

  return data;
};
