import React from "react";
import Heading from "../UI/Heading/Heading";
import DistributionList from "./DistributionList/DistributionList";
import PercentageList from "../../assets/img/PercentageList.svg";
import ProtectIcon from "../../assets/img/protectIcon.svg";

const Tokenomics = () => {
  return (
    <div
      id="Tokenomics"
      className="pt-[100px] md:pt-[436px] flex flex-col items-center md:items-start gap-6 md:gap-10 relative z-20"
    >
      <Heading type="h2_main">TOKENOMICS</Heading>
      <Heading type="h3_white">100% supply — community sharing</Heading>
      <div className="relative w-full flex flex-col gap-2 items-center md:items-start">
        <div className="lg:absolute top-[0px] lg:right-[-180px] 2xl:right-[-90px] w-[300px] md:w-[600px] ">
          <img src={PercentageList} alt="PercentageList" />
        </div>
        <div>
          <DistributionList />
        </div>
      </div>
      <div className="flex gap-4 items-center text-neutral-white font-montserrat border-neutral-orange font-bold border-2 rounded-3xl w-[300px] md:w-[630px] p-[10px] md:p-[22px]">
        <p className="font-montserrat text-[14px] md:text-[30px] ">
          100% project's liquidity locked
        </p>
        <img
          src={ProtectIcon}
          alt="ProtectIcon"
          className="w-[31px] md:w-[50px]"
        />
      </div>
    </div>
  );
};

export default Tokenomics;
