import React, { useEffect, useState } from "react";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";

const navigationLinks = [
  { name: "Main", href: "#Main" },
  { name: "Tokenomics", href: "#Tokenomics" },
  { name: "Utilities", href: "#Utilities" },
  { name: "Influencers", href: "#Influencers" },
  { name: "Community", href: "#Community" },
];

export const Header = () => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const toggleBodyOverflow = (state) => {
      document.body.style.overflow = state ? "hidden" : "";
    };

    toggleBodyOverflow(opened);

    return () => toggleBodyOverflow(false);
  }, [opened]);

  return (
    <header className="bg-background-bgDark100 relative py-8 px-4 z-[60]">
      <div className="max-w-7xl mx-auto relative">
        <DesktopMenu navigationLinks={navigationLinks} />
        <MobileMenu
          opened={opened}
          setOpened={setOpened}
          navigationLinks={navigationLinks}
        />
      </div>
    </header>
  );
};
