import React, { useState } from "react";
import "./MainMenu.css";

const MainMenu = ({ navigationLinks }) => {
  const [current, setCurrent] = useState("#home");

  return (
    <div className="hidden md:block">
      <nav className="ml-10 flex items-baseline space-x-1">
        {navigationLinks.map((link) => (
          <a
            key={link.name}
            href={link.href}
            className={`${
              link.href === current
                ? "bg-background-bgDark300 "
                : "text-gray-300 "
            } hover-underline-animation px-3 py-2 rounded-md text-base text-neutral-white font-montserrat font-bold `}
            aria-current={link.href === current ? "page" : undefined}
            onClick={() => setCurrent(link.href)}
          >
            {link.name}
          </a>
        ))}
      </nav>
    </div>
  );
};

export default MainMenu;
