import React from "react";
import LogoImg from "../../../assets/img/bee.png";

export const Logo = () => {
  return (
    <div className="flex-shrink-0">
      <a href="#Main">
        <img
          src={LogoImg}
          alt="NCTR Logo"
          className="w-[60px] md:w-[115px] h-[55px] md:h-[105px]"
        />
      </a>
    </div>
  );
};
