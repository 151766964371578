import React from "react";

import Heading from "../../UI/Heading/Heading";

const MainHeading = () => {
  return (
    <div className="flex gap-8 items-center justify-center md:justify-start">
      <Heading type="h1_main">$NCTR</Heading>
    </div>
  );
};

export default MainHeading;
