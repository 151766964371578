import React from "react";

const TwitterIcon = () => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <svg
        width="29"
        height="25"
        viewBox="0 0 29 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 3.34166C27.9335 3.82286 26.7879 4.14799 25.5835 4.29495C26.8122 3.54584 27.7547 2.35716 28.1992 0.94218C27.0497 1.63666 25.7764 2.14127 24.4213 2.41178C23.3356 1.2361 21.7902 0.5 20.0788 0.5C16.7938 0.5 14.1296 3.21291 14.1296 6.56047C14.1296 7.03387 14.1833 7.49426 14.2829 7.93904C9.3363 7.68543 4.95424 5.27295 2.01925 1.60805C1.50837 2.50542 1.21461 3.54584 1.21461 4.6539C1.21461 6.75555 2.26574 8.61141 3.86096 9.69736C2.88518 9.66484 1.96816 9.39303 1.16608 8.93915V9.01718C1.16608 11.9525 3.21598 14.4014 5.93896 14.958C5.4383 15.0959 4.91337 15.1687 4.37184 15.1687C3.98868 15.1687 3.61446 15.1323 3.25174 15.062C4.00912 17.468 6.20589 19.2211 8.81009 19.268C6.77297 20.8936 4.20708 21.8625 1.42024 21.8625C0.940016 21.8625 0.466176 21.8339 0 21.778C2.63358 23.4986 5.76015 24.5 9.11917 24.5C20.0647 24.5 26.0484 15.2688 26.0484 7.26276C26.0484 7.00266 26.042 6.73995 26.0305 6.47984C27.1928 5.62409 28.2018 4.55896 28.9974 3.34556L29 3.34166Z"
          fill={hovered ? "#FFC107" : "white"}
        />
      </svg>
    </span>
  );
};

export default TwitterIcon;
