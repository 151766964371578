import React from "react";

const TgIcon = () => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <svg
        width="33"
        height="29"
        viewBox="0 0 33 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9489 18.9539L12.403 26.7714C13.1841 26.7714 13.5223 26.4298 13.928 26.0196L17.5897 22.4567L25.1771 28.114C26.5686 28.9036 27.549 28.4878 27.9244 26.8106L32.9048 3.04995L32.9062 3.04855C33.3475 0.954165 32.1623 0.13517 30.8065 0.648967L1.53203 12.0603C-0.465892 12.8499 -0.435641 13.9839 1.1924 14.4977L8.6767 16.8679L26.0612 5.79254C26.8794 5.24094 27.6233 5.54614 27.0114 6.09774L12.9489 18.9539Z"
          fill={hovered ? "#FFC107" : "white"}
        />
      </svg>
    </span>
  );
};

export default TgIcon;
