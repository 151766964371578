import React from "react";
import bGMobile8 from "../../../assets/img/bg/BGMobile8.png";

const BGMobile8 = () => {
  return (
    <img
      src={bGMobile8}
      alt="coin"
      className="absolute md:hidden top-[3300px] right-0"
    />
  );
};

export default BGMobile8;
