import React from "react";
import Heading from "../UI/Heading/Heading";
import tg from "../../assets/img/tgBigIcon.svg";
import twitter from "../../assets/img/twitterBigIcon.svg";
import megaphone from "../../assets/img/megaphone.svg";

const Community = () => {
  return (
    <div
      id="Community"
      className="pt-[100px] relative z-20 md:pt-[436px] flex flex-col items-center  gap-4 md:gap-10 pb-[100px] md:pb-[200px]"
    >
      <Heading type="h2_white">COMMUNITY</Heading>
      <div className="flex gap-[50px]">
        <a href="https://t.me/nctr_group" target="_blank" rel="noreferrer">
          <img src={tg} alt="tg icon" className="w-[45px]  md:w-[80px]" />
        </a>
        <a
          href="https://twitter.com/nctr_solana"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={twitter}
            alt="twitter icon"
            className="w-[50px] md:w-[85px]"
          />
        </a>
        <a href="https://t.me/nctr_ann" target="_blank" rel="noreferrer">
          <img
            src={megaphone}
            alt="tg icon"
            className="w-[50px]  md:w-[100px] relative top-[-9px] md:top-[-24px] left-[-10px] md:left-[-5px]"
          />
        </a>
      </div>
    </div>
  );
};

export default Community;
