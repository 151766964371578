import React from "react";

const BG2 = () => {
  return (
    <svg
      width="212"
      height="332"
      viewBox="0 0 212 332"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute  hidden xl:block top-[500px] right-0"
    >
      <path
        d="M115.531 107.872C119.238 105.861 123.709 105.855 127.421 107.856L148.043 118.97L168.011 131.219C171.606 133.424 173.847 137.293 173.97 141.508L174.656 164.925L174.032 188.342C173.92 192.558 171.69 196.433 168.101 198.648L148.165 210.949L127.572 222.118C123.865 224.129 119.394 224.135 115.682 222.134L95.0603 211.02L75.0916 198.771C71.4968 196.566 69.2562 192.697 69.1328 188.481L68.4473 165.065L69.0707 141.647C69.1829 137.432 71.4133 133.557 75.0022 131.342L94.9384 119.041L115.531 107.872Z"
        stroke="#FFB21C"
      />
      <path
        d="M54.7356 212.53C58.5908 210.439 63.2406 210.432 67.1014 212.513L87.7355 223.634L107.716 235.89C111.455 238.184 113.785 242.207 113.913 246.591L114.599 270.022L113.975 293.453C113.859 297.838 111.539 301.868 107.807 304.171L87.8585 316.48L67.254 327.656C63.3988 329.747 58.749 329.753 54.8882 327.672L34.2541 316.551L14.2735 304.295C10.5349 302.001 8.20471 297.978 8.07637 293.594L7.39043 270.164L8.01425 246.732C8.13097 242.347 10.4505 238.318 14.183 236.014L34.1311 223.705L54.7356 212.53Z"
        fill="#FFB21C"
      />
      <path
        d="M236.643 107.872C240.35 105.861 244.821 105.855 248.534 107.856L269.155 118.97L289.124 131.219C292.719 133.424 294.959 137.293 295.083 141.508L295.768 164.925L295.145 188.342C295.033 192.558 292.802 196.433 289.213 198.648L269.277 210.949L248.685 222.118C244.978 224.129 240.507 224.135 236.795 222.134L216.173 211.02L196.204 198.771C192.61 196.566 190.369 192.697 190.246 188.481L189.56 165.065L190.184 141.647C190.296 137.432 192.526 133.557 196.115 131.342L216.051 119.041L236.643 107.872Z"
        stroke="#FFB21C"
      />
      <path
        d="M175.974 3.87155C179.681 1.86091 184.152 1.85498 187.864 3.85579L208.486 14.9702L228.455 27.219C232.049 29.4241 234.29 33.293 234.413 37.5084L235.099 60.9246L234.476 84.3424C234.363 88.5581 232.133 92.433 228.544 94.6476L208.608 106.949L188.016 118.118C184.309 120.129 179.838 120.135 176.125 118.134L155.504 107.02L135.535 94.7708C131.94 92.5658 129.7 88.6968 129.576 84.4815L128.891 61.0653L129.514 37.6474C129.626 33.4318 131.857 29.5569 135.446 27.3423L155.382 15.0406L175.974 3.87155Z"
        stroke="#FFB21C"
      />
      <path
        d="M176.087 212.969C179.794 210.959 184.265 210.953 187.977 212.953L208.599 224.068L228.567 236.317C232.162 238.522 234.403 242.391 234.526 246.606L235.212 270.022L234.588 293.44C234.476 297.656 232.246 301.531 228.657 303.745L208.721 316.047L188.128 327.216C184.421 329.227 179.951 329.233 176.238 327.232L155.616 316.117L135.648 303.868C132.053 301.663 129.812 297.794 129.689 293.579L129.003 270.163L129.627 246.745C129.739 242.529 131.969 238.655 135.558 236.44L155.495 224.138L176.087 212.969Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BG2;
