import React from "react";

const BGMobile4 = () => {
  return (
    <svg
      width="57"
      height="70"
      viewBox="0 0 57 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute md:hidden top-[740px] left-0"
    >
      <path
        d="M22.7989 2.03264C24.43 1.14796 26.3972 1.14535 28.0306 2.0257L40.5545 8.77564L52.6819 16.2146C54.2636 17.1848 55.2494 18.8871 55.3037 20.7419L55.72 34.9629L55.3414 49.1849C55.292 51.0398 54.3107 52.7448 52.7316 53.7192L40.624 61.1902L28.118 67.9734C26.4869 68.858 24.5197 68.8607 22.8863 67.9803L10.3624 61.2303L-1.76493 53.7914C-3.34662 52.8212 -4.33249 51.1189 -4.38678 49.2641L-4.80312 35.0431L-4.42449 20.8211C-4.3751 18.9662 -3.39376 17.2612 -1.81464 16.2868L10.2929 8.81576L22.7989 2.03264Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BGMobile4;
