import React from "react";

import MainHeading from "./MainHeading/MainHeading";
import BottomHeading from "./BottomHeading/BottomHeading";
import ButtonsList from "./ButtonsList/ButtonsList";
import Paragraph from "../UI/Paragraph/Paragraph";
import coins from "../../assets/img/coins.png";
import CopyIcon from "../../assets/svg/CopyIcon";
import CopySuccessIcon from "../../assets/svg/CopySuccessIcon";

const MainSection = () => {
  const [isCopied, setIsCopied] = React.useState(false);

  const removeIsCopied = () => {
    setIsCopied(false);
  };

  const copyToClipboard = () => {
    const caCode = "AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC";
    navigator.clipboard
      .writeText(caCode)
      .then(() => {
        setIsCopied(true);
        setTimeout(removeIsCopied, 3000);
      })
      .catch((err) => {
        // Handle error
        console.error("Error copying text to clipboard", err);
      });
  };

  return (
    <div
      id="Main"
      className="pt-10 md:pt-20  z-20 flex flex-col gap-6 md:gap-10 relative items-center md:items-start"
    >
      <div className="flex flex-col gap-4">
        <MainHeading />
        <BottomHeading />
      </div>
      <ButtonsList />
      <div className="bg-background-gray h-[2px] w-[300px] md:hidden relative top-[5px] md:top-[-10px]"></div>
      <div className="flex flex-col gap-4">
        <Paragraph
          type="p1_main"
          className="max-w-[770px] text-center md:text-left "
        >
          <span className="!font-semibold !text-neutral-orange">$NCTR</span> is
          more than just a meme token.
        </Paragraph>
        <Paragraph
          type="p1_main"
          className="max-w-[770px] text-center md:text-left "
        >
          In October 2022, it was created to support powerful utilities
          developed by{" "}
          <a
            href="https://www.tensor.trade/trade/lazy_family"
            target="_blank"
            rel="noreferrer"
            className="!font-semibold !text-neutral-orange"
          >
            Lazy Family
          </a>
          , a community of crypto enthusiasts and visioners.
        </Paragraph>
        <Paragraph
          type="p1_main"
          className="max-w-[770px] text-center md:text-left "
        >
          Grab some $NCTR and join the Family!
        </Paragraph>
        <Paragraph
          type="p1_main"
          className="max-w-[770px] text-center md:text-left  break-all"
        >
          CA: AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC
          {isCopied ? (
            <CopySuccessIcon />
          ) : (
            <CopyIcon onClickHandle={copyToClipboard} />
          )}
        </Paragraph>
      </div>

      <img
        src={coins}
        alt="coins"
        className="absolute right-[0px] 2xl:right-[50px] top-0 hidden xl:block"
      />
    </div>
  );
};

export default MainSection;
