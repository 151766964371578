import React from "react";

const DistributionList = () => {
  return (
    <div className="text-neutral-white  space-y-2 flex items-center gap-4">
      <div className="font-montserrat font-medium flex flex-col items-end text-[22px] md:text-4xl gap-3 justify-end relative top-[4px]">
        <span className="text-neutral-red font-bold">80% </span>
        <span className="text-neutral-orange font-bold">15% </span>
        <span className="text-neutral-orange font-bold">4% </span>
        <span className="text-neutral-orange font-bold">1% </span>
      </div>

      <div className="bg-background-gray h-[155px] md:h-[200px] w-[2px] relative top-[5px] "></div>

      <div className=" font-montserrat font-medium flex flex-col items-start text-[14px] md:text-4xl gap-[24px] md:gap-3">
        <span className="text-neutral-white ">
          Burnt <span className="font-semibold text-neutral-orange">(12m)</span>{" "}
        </span>
        <span className="text-neutral-white ">
          Lazy Family Staking{" "}
          <span className="font-semibold text-neutral-orange">(2.25m)</span>
        </span>
        <span className="text-neutral-white ">
          Community Airdrops{" "}
          <span className="font-semibold text-neutral-orange">(600k)</span>
        </span>
        <span className="text-neutral-white">
          Liquidity{" "}
          <span className="font-semibold text-neutral-orange">(170k)</span>{" "}
        </span>
      </div>
    </div>
  );
};

export default DistributionList;
