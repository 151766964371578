import React from "react";
import bGMobile6 from "../../../assets/img/bg/BGMobile6.png";

const BGMobile6 = () => {
  return (
    <img
      src={bGMobile6}
      alt="coin"
      className="absolute md:hidden top-[2600px] right-0"
    />
  );
};

export default BGMobile6;
