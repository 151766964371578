import React from "react";
import { JupiterModal } from "../UI/JupiterModal/JupiterModal";
import Heading from "../UI/Heading/Heading";

const BuyNCTR = () => {
  return (
    <div
      id="BuyNCTR"
      className="pt-[100px] md:pt-[436px] relative z-50 flex flex-col items-center md:items-start gap-4 md:gap-10"
    >
      <Heading type="h2_main">BUY $NCTR</Heading>
      <JupiterModal />
    </div>
  );
};

export default BuyNCTR;
