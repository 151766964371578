import React from "react";

const BG4 = () => {
  return (
    <svg
      width="487"
      height="397"
      viewBox="0 0 487 397"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute  hidden xl:block top-[1720px] "
    >
      <path
        d="M127.016 223.118C123.309 225.128 118.838 225.134 115.126 223.133L94.5038 212.019L74.5351 199.77C70.9403 197.565 68.6997 193.696 68.5763 189.481L67.8908 166.065L68.5142 142.647C68.6264 138.431 70.8568 134.556 74.4457 132.342L94.3819 120.04L114.974 108.871C118.681 106.86 123.152 106.854 126.864 108.855L147.486 119.97L167.455 132.218C171.05 134.423 173.29 138.292 173.414 142.508L174.099 165.924L173.476 189.342C173.363 193.557 171.133 197.432 167.544 199.647L147.608 211.949L127.016 223.118Z"
        stroke="#FFB21C"
      />
      <path
        d="M188.016 118.118C184.309 120.129 179.838 120.135 176.126 118.134L155.504 107.02L135.535 94.7707C131.94 92.5657 129.7 88.6967 129.576 84.4814L128.891 61.0652L129.514 37.6473C129.626 33.4316 131.857 29.5568 135.446 27.3422L155.382 15.0405L175.974 3.87142C179.681 1.86078 184.152 1.85485 187.864 3.85566L208.486 14.97L228.455 27.2189C232.05 29.4239 234.29 33.2929 234.414 37.5083L235.099 60.9244L234.476 84.3423C234.363 88.558 232.133 92.4329 228.544 94.6474L208.608 106.949L188.016 118.118Z"
        stroke="#FFB21C"
      />
      <path
        d="M310.913 327.215C307.206 329.226 302.735 329.231 299.023 327.231L278.401 316.116L258.433 303.867C254.838 301.662 252.597 297.793 252.474 293.578L251.788 270.162L252.412 246.744C252.524 242.528 254.754 238.653 258.343 236.439L278.279 224.137L298.872 212.968C302.579 210.957 307.049 210.952 310.762 212.952L331.384 224.067L351.352 236.316C354.947 238.521 357.188 242.39 357.311 246.605L357.997 270.021L357.373 293.439C357.261 297.655 355.031 301.53 351.442 303.744L331.505 316.046L310.913 327.215Z"
        stroke="#FFB21C"
      />
      <path
        d="M249.356 223.118C245.649 225.128 241.178 225.134 237.466 223.133L216.844 212.019L196.875 199.77C193.281 197.565 191.04 193.696 190.917 189.481L190.231 166.065L190.855 142.647C190.967 138.431 193.197 134.556 196.786 132.342L216.722 120.04L237.314 108.871C241.021 106.86 245.492 106.854 249.205 108.855L269.826 119.97L289.795 132.218C293.39 134.423 295.631 138.292 295.754 142.508L296.439 165.924L295.816 189.342C295.704 193.557 293.473 197.432 289.885 199.647L269.948 211.949L249.356 223.118Z"
        stroke="#FFB21C"
      />
      <path
        d="M371.016 223.118C367.309 225.128 362.838 225.134 359.126 223.133L338.504 212.019L318.535 199.77C314.94 197.565 312.7 193.696 312.576 189.481L311.891 166.065L312.514 142.647C312.626 138.431 314.857 134.556 318.446 132.342L338.382 120.04L358.974 108.871C362.681 106.86 367.152 106.854 370.864 108.855L391.486 119.97L411.455 132.218C415.05 134.423 417.29 138.292 417.414 142.508L418.099 165.924L417.476 189.342C417.363 193.557 415.133 197.432 411.544 199.647L391.608 211.949L371.016 223.118Z"
        stroke="#FFB21C"
      />
      <path
        d="M188.799 327.215C185.092 329.226 180.622 329.231 176.909 327.231L156.287 316.116L136.319 303.867C132.724 301.662 130.483 297.793 130.36 293.578L129.674 270.162L130.298 246.744C130.41 242.528 132.64 238.653 136.229 236.439L156.166 224.137L176.758 212.968C180.465 210.957 184.936 210.952 188.648 212.952L209.27 224.067L229.239 236.316C232.833 238.521 235.074 242.39 235.197 246.605L235.883 270.021L235.259 293.439C235.147 297.655 232.917 301.53 229.328 303.744L209.392 316.046L188.799 327.215Z"
        stroke="#FFB21C"
      />
      <path
        d="M66.6857 327.215C62.9787 329.226 58.5078 329.231 54.7955 327.231L34.1737 316.116L14.205 303.867C10.6102 301.662 8.36963 297.793 8.24621 293.578L7.56067 270.162L8.18413 246.744C8.29637 242.528 10.5267 238.653 14.1156 236.439L34.0518 224.137L54.644 212.968C58.351 210.957 62.8219 210.952 66.5342 212.952L87.156 224.067L107.125 236.316C110.72 238.521 112.96 242.39 113.084 246.605L113.769 270.021L113.146 293.439C113.033 297.655 110.803 301.53 107.214 303.744L87.2779 316.046L66.6857 327.215Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BG4;
