import React from "react";

const BG5 = () => {
  return (
    <svg
      width="360"
      height="332"
      viewBox="0 0 360 332"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-[2100px] hidden xl:block"
    >
      <path
        d="M-0.540362 107.969C-4.24736 105.958 -8.71828 105.952 -12.4306 107.953L-33.0523 119.067L-53.0211 131.316C-56.6159 133.521 -58.8564 137.39 -58.9799 141.606L-59.6654 165.022L-59.0419 188.44C-58.9297 192.655 -56.6994 196.53 -53.1105 198.745L-33.1743 211.046L-12.5821 222.215C-8.87507 224.226 -4.40415 224.232 -0.691832 222.231L19.9299 211.117L39.8987 198.868C43.4934 196.663 45.734 192.794 45.8574 188.579L46.543 165.162L45.9195 141.745C45.8073 137.529 43.577 133.654 39.9881 131.439L20.0518 119.138L-0.540362 107.969Z"
        stroke="#FFB21C"
      />
      <path
        d="M60.698 212.529C56.8427 210.438 52.193 210.431 48.3322 212.512L27.6981 223.633L7.71747 235.89C3.97892 238.183 1.6487 242.206 1.52035 246.59L0.834409 270.021L1.45823 293.452C1.57496 297.837 3.89449 301.867 7.62695 304.17L27.5751 316.479L48.1795 327.655C52.0348 329.746 56.6846 329.752 60.5454 327.671L81.1794 316.55L101.16 304.294C104.899 302 107.229 297.977 107.357 293.593L108.043 270.163L107.419 246.731C107.303 242.347 104.983 238.317 101.251 236.013L81.3025 223.704L60.698 212.529Z"
        fill="#FFB21C"
      />
      <path
        d="M122.038 107.529C118.183 105.438 113.533 105.432 109.673 107.513L89.0385 118.634L69.0578 130.89C65.3193 133.183 62.989 137.207 62.8607 141.591L62.1747 165.021L62.7986 188.453C62.9153 192.837 65.2348 196.867 68.9673 199.17L88.9154 211.479L109.52 222.655C113.375 224.746 118.025 224.752 121.886 222.671L142.52 211.55L162.5 199.294C166.239 197.001 168.569 192.977 168.698 188.593L169.383 165.163L168.76 141.731C168.643 137.347 166.323 133.317 162.591 131.014L142.643 118.705L122.038 107.529Z"
        fill="#FFB21C"
      />
    </svg>
  );
};

export default BG5;
