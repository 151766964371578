import React from "react";

const BGMobile3 = () => {
  return (
    <svg
      width="62"
      height="69"
      viewBox="0 0 62 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute md:hidden top-[720px] right-0"
    >
      <path
        d="M28.1049 0.905137C29.8843 -0.0599701 32.0304 -0.0628157 33.8123 0.897571L46.3485 7.65415L58.4877 15.1004C60.2132 16.1588 61.2887 18.0159 61.3479 20.0393L61.7647 34.2742L61.3857 48.5103C61.3318 50.5338 60.2612 52.3937 58.5386 53.4567L46.4191 60.9351L33.9008 67.7249C32.1215 68.69 29.9754 68.6928 28.1935 67.7324L15.6573 60.9759L3.51807 53.5296C1.79259 52.4712 0.717099 50.6141 0.657863 48.5908L0.241123 34.3558L0.620125 20.1198C0.673996 18.0962 1.74455 16.2363 3.46723 15.1733L15.5867 7.69492L28.1049 0.905137Z"
        fill="#FFB21C"
      />
    </svg>
  );
};

export default BGMobile3;
