import React, { useEffect } from "react";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import "./JupiterModal.css";
import Jupiter from "../../../assets/img/jupiter.png";
// Wallet
import { useWallet, useConnection } from "@solana/wallet-adapter-react";

// Store
import useUserSOLBalanceStore from "../../../stores/useUserSOLBalanceStore";

export const JupiterModal = () => {
  const wallet = useWallet();
  const { connection } = useConnection();

  const balance = useUserSOLBalanceStore((s) => s.balance);
  const { getUserSOLBalance } = useUserSOLBalanceStore();

  useEffect(() => {
    if (wallet.publicKey) {
      console.log(wallet.publicKey.toBase58());
      getUserSOLBalance(wallet.publicKey, connection);
    }
  }, [wallet.publicKey, connection, getUserSOLBalance]);

  useEffect(() => {
    window.Jupiter.init({
      displayMode: "integrated",
      integratedTargetId: "integrated-terminal",
      endpoint:
        "https://rpc.helius.xyz/?api-key=7d2040bc-1d8b-4db9-a9f3-534568617037",
      formProps: {
        initialOutputMint: "AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC",
        initialInputMint: "So11111111111111111111111111111111111111112",
      },
    });

    // Define a function to insert the "BUY" string
    const insertCustomButton = () => {
      const targetAnchor = document.querySelector(
        "#jupiter-terminal a.flex.items-center.space-x-2"
      );
      console.log(targetAnchor);
      if (targetAnchor) {
        const spanElement = targetAnchor.querySelector("span");
        if (spanElement) {
          spanElement.textContent = "Buy on Jupiter";
        }

        targetAnchor.classList.add(
          "!text-neutral-white",
          "!w-40",
          "md:!w-48",
          "!h-12",
          "!flex",
          "!gap-1",
          "md:!gap-2",
          "!font-montserrat",
          "!border-neutral-orange",
          "!font-bold",
          "!border-2",
          "!rounded-md",
          "!hover:text-neutral-orange",
          "!hover:w-56",
          "!items-center",
          "justify-center"
        );

        return true;
      }
      return false;
    };

    const intervalId = setInterval(() => {
      const isInserted = insertCustomButton();
      if (isInserted) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full bg-background-grayPrimary rounded-xl ">
      <div id="integrated-terminal"></div>
    </div>
  );
};
