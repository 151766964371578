import React from "react";

const BG1 = () => {
  return (
    <svg
      width="35"
      height="120"
      viewBox="0 0 35 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-[90px] hidden md:block"
    >
      <path
        d="M-12.7463 117.558C-16.6016 119.649 -21.2513 119.655 -25.1122 117.574L-45.7462 106.453L-65.7269 94.1969C-69.4654 91.9037 -71.7956 87.88 -71.924 83.496L-72.6099 60.0659L-71.9861 36.634C-71.8694 32.2497 -69.5498 28.2198 -65.8174 25.9167L-45.8693 13.6076L-25.2648 2.43191C-21.4095 0.340842 -16.7598 0.33467 -12.8989 2.41551L7.7351 13.5365L27.7158 25.7927C31.4543 28.0859 33.7845 32.1097 33.9129 36.4936L34.5988 59.9238L33.975 83.3556C33.8583 87.7399 31.5387 91.7698 27.8063 94.0729L7.85817 106.382L-12.7463 117.558Z"
        fill="#FFB21C"
      />
    </svg>
  );
};

export default BG1;
