import React from "react";

const BGMobile1 = () => {
  return (
    <svg
      width="61"
      height="191"
      viewBox="0 0 61 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute md:hidden top-[240px] left-0"
    >
      <path
        d="M22.7989 62.0326C24.43 61.148 26.3972 61.1453 28.0306 62.0257L40.5545 68.7756L52.6819 76.2146C54.2636 77.1848 55.2494 78.8871 55.3037 80.7419L55.72 94.9629L55.3414 109.185C55.292 111.04 54.3107 112.745 52.7316 113.719L40.624 121.19L28.118 127.973C26.4869 128.858 24.5197 128.861 22.8863 127.98L10.3624 121.23L-1.76493 113.791C-3.34662 112.821 -4.33249 111.119 -4.38678 109.264L-4.80312 95.0431L-4.42449 80.8211C-4.3751 78.9662 -3.39376 77.2612 -1.81464 76.2868L10.2929 68.8158L22.7989 62.0326Z"
        stroke="#FFB21C"
      />
      <path
        d="M-12.6567 2.03264C-11.0256 1.14796 -9.05838 1.14535 -7.42496 2.0257L5.09893 8.77564L17.2263 16.2146C18.808 17.1848 19.7938 18.8871 19.8481 20.7419L20.2645 34.9629L19.8858 49.1849C19.8365 51.0398 18.8551 52.7448 17.276 53.7192L5.16842 61.1902L-7.33755 67.9734C-8.96863 68.858 -10.9358 68.8607 -12.5692 67.9803L-25.0932 61.2303L-37.2205 53.7914C-38.8022 52.8212 -39.7881 51.1189 -39.8424 49.2641L-40.2587 35.0431L-39.8801 20.8211C-39.8307 18.9662 -38.8493 17.2612 -37.2702 16.2868L-25.1626 8.81576L-12.6567 2.03264Z"
        stroke="#FFB21C"
      />
      <path
        d="M-12.207 122.345C-10.5759 121.46 -8.60867 121.457 -6.97525 122.338L5.54864 129.088L17.676 136.527C19.2577 137.497 20.2436 139.199 20.2979 141.054L20.7142 155.275L20.3356 169.497C20.2862 171.352 19.3048 173.057 17.7257 174.031L5.61813 181.502L-6.88784 188.285C-8.51892 189.17 -10.4861 189.173 -12.1195 188.292L-24.6435 181.542L-36.7708 174.103C-38.3525 173.133 -39.3383 171.431 -39.3926 169.576L-39.809 155.355L-39.4303 141.133C-39.381 139.278 -38.3996 137.573 -36.8205 136.599L-24.7129 129.128L-12.207 122.345Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BGMobile1;
