import React from "react";
import bG8 from "../../../assets/img/bg/BG8.png";

const BG8 = () => {
  return (
    <img
      src={bG8}
      alt="coin"
      className="absolute top-[3200px] hidden md:block"
    />
  );
};

export default BG8;
