import React from "react";

const BG10 = () => {
  return (
    <svg
      width="517"
      height="434"
      viewBox="0 0 517 434"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute  hidden md:block top-[5100px] right-0"
    >
      <path
        d="M360.531 224.203C364.238 226.213 368.709 226.219 372.421 224.218L393.043 213.104L413.011 200.855C416.606 198.65 418.847 194.781 418.97 190.566L419.656 167.15L419.032 143.732C418.92 139.516 416.69 135.641 413.101 133.427L393.165 121.125L372.572 109.956C368.865 107.945 364.394 107.939 360.682 109.94L340.06 121.055L320.092 133.303C316.497 135.508 314.256 139.377 314.133 143.593L313.447 167.009L314.071 190.427C314.183 194.642 316.413 198.517 320.002 200.732L339.938 213.034L360.531 224.203Z"
        stroke="#FFB21C"
      />
      <path
        d="M298.974 328.107C302.681 330.118 307.152 330.124 310.864 328.123L331.486 317.008L351.455 304.759C355.049 302.554 357.29 298.685 357.413 294.47L358.099 271.054L357.476 247.636C357.363 243.42 355.133 239.546 351.544 237.331L331.608 225.029L311.016 213.86C307.309 211.85 302.838 211.844 299.125 213.844L278.504 224.959L258.535 237.208C254.94 239.413 252.7 243.282 252.576 247.497L251.891 270.913L252.514 294.331C252.626 298.547 254.857 302.422 258.446 304.636L278.382 316.938L298.974 328.107Z"
        stroke="#FFB21C"
      />
      <path
        d="M177.974 328.107C181.681 330.118 186.152 330.124 189.864 328.123L210.486 317.009L230.455 304.76C234.049 302.555 236.29 298.686 236.413 294.471L237.099 271.054L236.476 247.637C236.363 243.421 234.133 239.546 230.544 237.331L210.608 225.03L190.016 213.861C186.309 211.85 181.838 211.844 178.125 213.845L157.504 224.959L137.535 237.208C133.94 239.413 131.7 243.282 131.576 247.498L130.891 270.914L131.514 294.332C131.626 298.547 133.857 302.422 137.446 304.637L157.382 316.938L177.974 328.107Z"
        stroke="#FFB21C"
      />
      <path
        d="M54.974 328.107C58.681 330.118 63.1519 330.124 66.8642 328.123L87.4859 317.009L107.455 304.76C111.049 302.555 113.29 298.686 113.413 294.471L114.099 271.054L113.476 247.637C113.363 243.421 111.133 239.546 107.544 237.331L87.6079 225.03L67.0156 213.861C63.3087 211.85 58.8377 211.844 55.1254 213.845L34.5037 224.959L14.5349 237.208C10.9402 239.413 8.69956 243.282 8.57615 247.498L7.89062 270.914L8.51407 294.332C8.62631 298.547 10.8566 302.422 14.4455 304.637L34.3818 316.938L54.974 328.107Z"
        stroke="#FFB21C"
      />
      <path
        d="M111.974 224.112C115.681 226.123 120.152 226.129 123.864 224.128L144.486 213.014L164.455 200.765C168.049 198.56 170.29 194.691 170.413 190.475L171.099 167.059L170.476 143.641C170.363 139.426 168.133 135.551 164.544 133.336L144.608 121.035L124.016 109.866C120.309 107.855 115.838 107.849 112.125 109.85L91.5037 120.964L71.5349 133.213C67.9402 135.418 65.6996 139.287 65.5762 143.502L64.8906 166.919L65.5141 190.336C65.6263 194.552 67.8566 198.427 71.4455 200.642L91.3818 212.943L111.974 224.112Z"
        stroke="#FFB21C"
      />
      <path
        d="M299.974 119.106C303.681 121.117 308.152 121.123 311.864 119.122L332.486 108.007L352.455 95.7585C356.049 93.5535 358.29 89.6845 358.413 85.4691L359.099 62.053L358.476 38.6351C358.363 34.4194 356.133 30.5445 352.544 28.33L332.608 16.0282L312.016 4.85921C308.309 2.84857 303.838 2.84263 300.125 4.84344L279.504 15.9578L259.535 28.2067C255.94 30.4117 253.7 34.2807 253.576 38.4961L252.891 61.9122L253.514 85.3301C253.626 89.5458 255.857 93.4207 259.446 95.6352L279.382 107.937L299.974 119.106Z"
        stroke="#FFB21C"
      />
      <path
        d="M481.405 224.642C485.26 226.733 489.91 226.739 493.771 224.659L514.405 213.538L534.386 201.281C538.124 198.988 540.454 194.964 540.583 190.58L541.269 167.15L540.645 143.718C540.528 139.334 538.209 135.304 534.476 133.001L514.528 120.692L493.923 109.516C490.068 107.425 485.418 107.419 481.558 109.5L460.924 120.621L440.943 132.877C437.204 135.17 434.874 139.194 434.746 143.578L434.06 167.008L434.684 190.44C434.8 194.824 437.12 198.854 440.852 201.157L460.801 213.467L481.405 224.642Z"
        fill="#FFB21C"
      />
      <path
        d="M420.974 328.203C424.681 330.213 429.152 330.219 432.864 328.218L453.486 317.104L473.455 304.855C477.049 302.65 479.29 298.781 479.413 294.566L480.099 271.15L479.476 247.732C479.363 243.516 477.133 239.641 473.544 237.427L453.608 225.125L433.016 213.956C429.309 211.945 424.838 211.939 421.125 213.94L400.504 225.055L380.535 237.303C376.94 239.508 374.7 243.377 374.576 247.593L373.891 271.009L374.514 294.427C374.626 298.642 376.857 302.517 380.446 304.732L400.382 317.034L420.974 328.203Z"
        stroke="#FFB21C"
      />
      <path
        d="M482.974 435.118C486.681 437.129 491.152 437.135 494.864 435.134L515.486 424.02L535.455 411.771C539.049 409.566 541.29 405.697 541.413 401.481L542.099 378.065L541.476 354.647C541.363 350.432 539.133 346.557 535.544 344.342L515.608 332.04L495.016 320.871C491.309 318.861 486.838 318.855 483.125 320.856L462.504 331.97L442.535 344.219C438.94 346.424 436.7 350.293 436.576 354.508L435.891 377.924L436.514 401.342C436.626 405.558 438.857 409.433 442.446 411.647L462.382 423.949L482.974 435.118Z"
        stroke="#FFB21C"
      />
      <path
        d="M542.974 328.203C546.681 330.213 551.152 330.219 554.864 328.218L575.486 317.104L595.455 304.855C599.049 302.65 601.29 298.781 601.413 294.566L602.099 271.15L601.476 247.732C601.363 243.516 599.133 239.641 595.544 237.427L575.608 225.125L555.016 213.956C551.309 211.945 546.838 211.939 543.125 213.94L522.504 225.055L502.535 237.303C498.94 239.508 496.7 243.377 496.576 247.593L495.891 271.009L496.514 294.427C496.626 298.642 498.857 302.517 502.446 304.732L522.382 317.034L542.974 328.203Z"
        stroke="#FFB21C"
      />
      <path
        d="M421.087 119.106C424.794 121.117 429.265 121.123 432.977 119.122L453.599 108.007L473.567 95.7585C477.162 93.5535 479.403 89.6845 479.526 85.4691L480.212 62.053L479.588 38.6351C479.476 34.4194 477.246 30.5445 473.657 28.33L453.721 16.0282L433.128 4.85921C429.421 2.84857 424.951 2.84263 421.238 4.84344L400.616 15.9578L380.648 28.2067C377.053 30.4117 374.812 34.2807 374.689 38.4961L374.003 61.9122L374.627 85.3301C374.739 89.5458 376.969 93.4207 380.558 95.6352L400.495 107.937L421.087 119.106Z"
        stroke="#FFB21C"
      />
      <path
        d="M542.974 119.106C546.681 121.117 551.152 121.123 554.864 119.122L575.486 108.007L595.455 95.7585C599.049 93.5535 601.29 89.6845 601.413 85.4691L602.099 62.053L601.476 38.6351C601.363 34.4194 599.133 30.5445 595.544 28.33L575.608 16.0282L555.016 4.85921C551.309 2.84857 546.838 2.84263 543.125 4.84344L522.504 15.9578L502.535 28.2067C498.94 30.4117 496.7 34.2807 496.576 38.4961L495.891 61.9122L496.514 85.3301C496.626 89.5458 498.857 93.4207 502.446 95.6352L522.382 107.937L542.974 119.106Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BG10;
