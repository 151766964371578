import React from "react";

const BGMobile5 = () => {
  return (
    <svg
      width="80"
      height="131"
      viewBox="0 0 80 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute md:hidden top-[1020px] right-0"
    >
      <path
        d="M66.8564 1.59313C68.6358 0.628018 70.7818 0.625173 72.5637 1.58556L85.0999 8.34213L97.2392 15.7884C98.9647 16.8468 100.04 18.7039 100.099 20.7273L100.516 34.9622L100.137 49.1982C100.083 51.2218 99.0127 53.0817 97.29 54.1447L85.1706 61.6231L72.6523 68.4129C70.8729 69.378 68.7269 69.3808 66.945 68.4204L54.4088 61.6639L42.2695 54.2176C40.5441 53.1592 39.4686 51.3021 39.4093 49.2787L38.9926 35.0438L39.3716 20.8078C39.4255 18.7842 40.496 16.9243 42.2187 15.8613L54.3382 8.38291L66.8564 1.59313Z"
        fill="#FFB21C"
      />
      <path
        d="M32.3433 62.3447C33.9744 61.46 35.9416 61.4574 37.575 62.3377L50.0989 69.0876L62.2263 76.5266C63.808 77.4968 64.7938 79.1991 64.8481 81.0539L65.2645 95.2749L64.8858 109.497C64.8365 111.352 63.8551 113.057 62.276 114.031L50.1684 121.502L37.6625 128.285C36.0314 129.17 34.0642 129.173 32.4308 128.292L19.9068 121.542L7.77951 114.103C6.19781 113.133 5.21195 111.431 5.15765 109.576L4.74132 95.3551L5.11995 81.1331C5.16933 79.2782 6.15067 77.5732 7.72979 76.5988L19.8374 69.1278L32.3433 62.3447Z"
        stroke="#FFB21C"
      />
      <path
        d="M102.291 62.3447C103.922 61.46 105.889 61.4574 107.522 62.3377L120.046 69.0876L132.174 76.5266C133.755 77.4968 134.741 79.1991 134.795 81.0539L135.212 95.2749L134.833 109.497C134.784 111.352 133.802 113.057 132.223 114.031L120.116 121.502L107.61 128.285C105.979 129.17 104.011 129.173 102.378 128.292L89.8541 121.542L77.7268 114.103C76.1451 113.133 75.1592 111.431 75.1049 109.576L74.6886 95.3551L75.0672 81.1331C75.1166 79.2782 76.0979 77.5732 77.6771 76.5988L89.7846 69.1278L102.291 62.3447Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BGMobile5;
