import React from "react";

const textStyles = {
  p1_main:
    "text-neutral-white font-montserrat text-[16px] md:text-[24px] font-medium  leading-8",
};

const Paragraph = ({
  type = "p1_main",
  children,
  className = "",
  ...props
}) => {
  const textStyle = textStyles[type];

  return (
    <p
      className={`${textStyle} text-${type} tracking-tighter ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};

export default Paragraph;
