import React from "react";

const BG6 = () => {
  return (
    <svg
      width="313"
      height="332"
      viewBox="0 0 313 332"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute  hidden md:block top-[2500px] right-0"
    >
      <path
        d="M115.292 107.432C119.147 105.341 123.797 105.335 127.658 107.416L148.292 118.537L168.273 130.793C172.011 133.086 174.342 137.11 174.47 141.494L175.156 164.924L174.532 188.356C174.415 192.74 172.096 196.77 168.363 199.073L148.415 211.382L127.811 222.558C123.955 224.649 119.306 224.655 115.445 222.574L94.8108 211.453L74.8301 199.197C71.0916 196.904 68.7614 192.88 68.633 188.496L67.9471 165.066L68.5709 141.634C68.6876 137.25 71.0072 133.22 74.7396 130.917L94.6877 118.608L115.292 107.432Z"
        fill="#FFB21C"
      />
      <path
        d="M54.7356 212.53C58.5908 210.439 63.2406 210.432 67.1014 212.513L87.7355 223.634L107.716 235.89C111.455 238.184 113.785 242.207 113.913 246.591L114.599 270.022L113.975 293.453C113.859 297.838 111.539 301.868 107.807 304.171L87.8585 316.48L67.254 327.656C63.3988 329.747 58.749 329.753 54.8882 327.672L34.2541 316.551L14.2735 304.295C10.5349 302.001 8.20471 297.978 8.07637 293.594L7.39043 270.164L8.01425 246.732C8.13097 242.347 10.4505 238.318 14.183 236.014L34.1311 223.705L54.7356 212.53Z"
        fill="#FFB21C"
      />
      <path
        d="M236.405 107.432C240.261 105.341 244.911 105.335 248.771 107.416L269.405 118.537L289.386 130.793C293.125 133.086 295.455 137.11 295.583 141.494L296.269 164.924L295.645 188.356C295.529 192.74 293.209 196.77 289.477 199.073L269.528 211.382L248.924 222.558C245.069 224.649 240.419 224.655 236.558 222.574L215.924 211.453L195.943 199.197C192.205 196.904 189.875 192.88 189.746 188.496L189.06 165.066L189.684 141.634C189.801 137.25 192.12 133.22 195.853 130.917L215.801 118.608L236.405 107.432Z"
        fill="#FFB21C"
      />
      <path
        d="M358.974 107.872C362.681 105.861 367.152 105.855 370.864 107.856L391.486 118.97L411.455 131.219C415.049 133.424 417.29 137.293 417.413 141.508L418.099 164.925L417.476 188.342C417.363 192.558 415.133 196.433 411.544 198.648L391.608 210.949L371.016 222.118C367.309 224.129 362.838 224.135 359.125 222.134L338.504 211.02L318.535 198.771C314.94 196.566 312.7 192.697 312.576 188.481L311.891 165.065L312.514 141.647C312.626 137.432 314.857 133.557 318.446 131.342L338.382 119.041L358.974 107.872Z"
        stroke="#FFB21C"
      />
      <path
        d="M175.736 3.43204C179.591 1.34097 184.241 1.3348 188.101 3.41564L208.735 14.5367L228.716 26.7928C232.455 29.0861 234.785 33.1098 234.913 37.4938L235.599 60.9239L234.975 84.3557C234.859 88.74 232.539 92.7699 228.807 95.0731L208.859 107.382L188.254 118.558C184.399 120.649 179.749 120.655 175.888 118.574L155.254 107.453L135.273 95.197C131.535 92.9038 129.205 88.8801 129.076 84.4961L128.39 61.066L129.014 37.6341C129.131 33.2498 131.451 29.22 135.183 26.9168L155.131 14.6077L175.736 3.43204Z"
        fill="#FFB21C"
      />
      <path
        d="M297.736 3.43204C301.591 1.34097 306.241 1.3348 310.101 3.41564L330.735 14.5367L350.716 26.7928C354.455 29.0861 356.785 33.1098 356.913 37.4938L357.599 60.9239L356.975 84.3557C356.859 88.74 354.539 92.7699 350.807 95.0731L330.859 107.382L310.254 118.558C306.399 120.649 301.749 120.655 297.888 118.574L277.254 107.453L257.273 95.197C253.535 92.9038 251.205 88.8801 251.076 84.4961L250.39 61.066L251.014 37.6341C251.131 33.2498 253.451 29.22 257.183 26.9168L277.131 14.6077L297.736 3.43204Z"
        fill="#FFB21C"
      />
      <path
        d="M176.087 212.969C179.794 210.959 184.265 210.953 187.977 212.953L208.599 224.068L228.568 236.317C232.163 238.522 234.403 242.391 234.527 246.606L235.212 270.022L234.589 293.44C234.477 297.656 232.246 301.531 228.657 303.745L208.721 316.047L188.129 327.216C184.422 329.227 179.951 329.233 176.239 327.232L155.617 316.117L135.648 303.868C132.053 301.663 129.813 297.794 129.689 293.579L129.004 270.163L129.627 246.745C129.74 242.529 131.97 238.655 135.559 236.44L155.495 224.138L176.087 212.969Z"
        stroke="#FFB21C"
      />
      <path
        d="M297.974 212.969C301.681 210.959 306.152 210.953 309.864 212.953L330.486 224.068L350.455 236.317C354.049 238.522 356.29 242.391 356.413 246.606L357.099 270.022L356.476 293.44C356.363 297.656 354.133 301.531 350.544 303.745L330.608 316.047L310.016 327.216C306.309 329.227 301.838 329.233 298.125 327.232L277.504 316.117L257.535 303.868C253.94 301.663 251.7 297.794 251.576 293.579L250.891 270.163L251.514 246.745C251.626 242.529 253.857 238.655 257.446 236.44L277.382 224.138L297.974 212.969Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BG6;
