import React from "react";
import Solana from "../../../assets/img/solana.png";

const BottomHeading = () => {
  return (
    <div className="flex gap-6 md:gap-8">
      <div>
        <p className="font-montserrat text-xl md:text-4xl flex flex-col items-end md:flex-row  md:items-start md:gap-4">
          <span className="text-neutral-orange font-semibold">No Honey</span>
          {""}
          <span className="text-neutral-white">No Money</span>
        </p>
      </div>
      <div className="bg-background-gray h-[47px] md:h-[60px] w-[2px] relative top-[5px] md:top-[-10px]"></div>

      <div className="flex gap-2 items-center relative md:top-[-2px]">
        <img
          src={Solana}
          alt="Solana Icon"
          className="relative md:top-[-5px] "
        />
        <div className="flex flex-col relative md:top-[-4px]  ">
          <p className="font-BenzinBold font-normal tracking-widest text-[9px] md:text-1xl text-neutral-white">
            SOLANA
          </p>
          <p className="font-BenzinBold font-normal tracking-widest text-[9px] md:text-1xl text-neutral-white relative ">
            BLOCKCHAIN
          </p>
        </div>
      </div>
    </div>
  );
};

export default BottomHeading;
