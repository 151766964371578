import BuyNCTR from "./Components/BuyNCTR/BuyNCTR";
import Community from "./Components/Community/Community";
import { Header } from "./Components/Header/Header";
import Influencers from "./Components/Influencers/Influencers";
import MainSection from "./Components/MainSection/MainSection";
import Tokenomics from "./Components/Tokenomics/Tokenomics";
import BG1 from "./Components/UI/BG/BG1";
import BG10 from "./Components/UI/BG/BG10";
import BG2 from "./Components/UI/BG/BG2";
import BG3 from "./Components/UI/BG/BG3";
import BG4 from "./Components/UI/BG/BG4";
import BG5 from "./Components/UI/BG/BG5";
import BG6 from "./Components/UI/BG/BG6";
import BG7 from "./Components/UI/BG/BG7";
import BG8 from "./Components/UI/BG/BG8";
import BG9 from "./Components/UI/BG/BG9";
import BGMobile1 from "./Components/UI/BGMobile/BGMobile1";
import BGMobile2 from "./Components/UI/BGMobile/BGMobile2";
import BGMobile3 from "./Components/UI/BGMobile/BGMobile3";
import BGMobile4 from "./Components/UI/BGMobile/BGMobile4";
import BGMobile5 from "./Components/UI/BGMobile/BGMobile5";
import BGMobile6 from "./Components/UI/BGMobile/BGMobile6";
import BGMobile7 from "./Components/UI/BGMobile/BGMobile7";
import BGMobile8 from "./Components/UI/BGMobile/BGMobile8";
import BGMobile9 from "./Components/UI/BGMobile/BGMobile9";
import Utilities from "./Components/Utilities/Utilities";

function App() {
  return (
    <>
      <div className="App bg-background-main relative min-h-[100vw] px-4 lg:px-6 container mx-auto">
        <Header />

        <MainSection />
        <Tokenomics />
        <BuyNCTR />
        <Utilities />
        <Influencers />
        <Community />
      </div>
      <BG1 />
      <BGMobile1 />
      <BG2 />
      <BGMobile2 />
      <BG3 />
      <BGMobile3 />
      <BG4 />
      <BGMobile4 />
      <BG5 />
      <BGMobile5 />
      <BG6 />
      <BGMobile6 />
      <BG7 />
      <BGMobile7 />
      <BG8 />
      <BGMobile8 />
      <BG9 />
      <BGMobile9 />
      <BG10 />
    </>
  );
}

export default App;
