import React from "react";
import bGMobile7 from "../../../assets/img/bg/BGMobile7.png";

const BGMobile7 = () => {
  return (
    <img
      src={bGMobile7}
      alt="coin"
      className="absolute md:hidden top-[2940px] left-0"
    />
  );
};

export default BGMobile7;
