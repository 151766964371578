import React from "react";

const BG3 = () => {
  return (
    <svg
      width="293"
      height="541"
      viewBox="0 0 293 541"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute  hidden md:block top-[720px] xl:top-[620px]"
    >
      <path
        d="M-5.74582 118.558C-9.6011 120.649 -14.2509 120.655 -18.1117 118.574L-38.7457 107.453L-58.7264 95.1969C-62.4649 92.9037 -64.7951 88.88 -64.9235 84.496L-65.6094 61.0659L-64.9856 37.634C-64.8689 33.2497 -62.5494 29.2198 -58.8169 26.9167L-38.8688 14.6076L-18.2643 3.43191C-14.409 1.34084 -9.75927 1.33467 -5.89846 3.41551L14.7356 14.5365L34.7163 26.7927C38.4548 29.0859 40.785 33.1097 40.9134 37.4936L41.5993 60.9238L40.9755 84.3556C40.8588 88.7399 38.5392 92.7698 34.8068 95.0729L14.8587 107.382L-5.74582 118.558Z"
        fill="#FFB21C"
      />
      <path
        d="M116.913 327.215C113.206 329.226 108.735 329.231 105.023 327.231L84.4013 316.116L64.4325 303.867C60.8378 301.662 58.5972 297.793 58.4737 293.578L57.7882 270.162L58.4117 246.744C58.5239 242.528 60.7542 238.653 64.3431 236.439L84.2793 224.137L104.872 212.968C108.579 210.957 113.049 210.952 116.762 212.952L137.384 224.067L157.352 236.316C160.947 238.521 163.188 242.39 163.311 246.605L163.997 270.021L163.373 293.439C163.261 297.655 161.031 301.53 157.442 303.744L137.505 316.046L116.913 327.215Z"
        stroke="#FFB21C"
      />
      <path
        d="M55.5945 223.557C51.7392 225.648 47.0895 225.654 43.2287 223.574L22.5946 212.453L2.61395 200.196C-1.1246 197.903 -3.45482 193.879 -3.58316 189.496L-4.26911 166.065L-3.64528 142.634C-3.52856 138.249 -1.20902 134.219 2.52344 131.916L22.4715 119.607L43.076 108.431C46.9313 106.34 51.5811 106.334 55.4419 108.415L76.0759 119.536L96.0566 131.792C99.7951 134.085 102.125 138.109 102.254 142.493L102.94 165.923L102.316 189.355C102.199 193.739 99.8796 197.769 96.1471 200.072L76.199 212.382L55.5945 223.557Z"
        fill="#FFB21C"
      />
      <path
        d="M177.016 223.118C173.309 225.128 168.838 225.134 165.126 223.133L144.504 212.019L124.535 199.77C120.94 197.565 118.7 193.696 118.576 189.481L117.891 166.065L118.514 142.647C118.626 138.431 120.857 134.556 124.446 132.342L144.382 120.04L164.974 108.871C168.681 106.86 173.152 106.854 176.864 108.855L197.486 119.97L217.455 132.218C221.05 134.423 223.29 138.292 223.414 142.508L224.099 165.924L223.476 189.342C223.363 193.557 221.133 197.432 217.544 199.647L197.608 211.949L177.016 223.118Z"
        stroke="#FFB21C"
      />
      <path
        d="M-5.20052 327.215C-8.90752 329.226 -13.3784 329.231 -17.0907 327.231L-37.7125 316.116L-57.6813 303.867C-61.276 301.662 -63.5166 297.793 -63.64 293.578L-64.3256 270.162L-63.7021 246.744C-63.5899 242.528 -61.3595 238.653 -57.7706 236.439L-37.8344 224.137L-17.2422 212.968C-13.5352 210.957 -9.0643 210.952 -5.35199 212.952L15.2698 224.067L35.2385 236.316C38.8333 238.521 41.0739 242.39 41.1973 246.605L41.8828 270.021L41.2594 293.439C41.1471 297.655 38.9168 301.53 35.3279 303.744L15.3917 316.046L-5.20052 327.215Z"
        stroke="#FFB21C"
      />
    </svg>
  );
};

export default BG3;
