import React, { useState } from "react";

const MobileMainMenu = ({ navigationLinks, setOpened }) => {
  const [current, setCurrent] = useState("");

  const handleClick = (href) => {
    setCurrent(href);
    setOpened(false);
  };

  return (
    <nav className="w-full flex flex-col items-center">
      {navigationLinks.map((link) => (
        <a
          key={link.name}
          href={link.href}
          className={`${
            link.href === current
              ? "bg-background-bgDark300 text-white"
              : "text-gray-300 hover:bg-background-bgDark300 hover:text-white"
          } hover-underline-animation block px-3 py-2 rounded-md text-sm max-w-[100px] text-center text-neutral-white font-montserrat font-bold `}
          aria-current={link.href === current ? "page" : undefined}
          onClick={() => handleClick(link.href)}
        >
          {link.name}
        </a>
      ))}
    </nav>
  );
};

export default MobileMainMenu;
