import React from "react";
import twitterIcon from "../../../assets/img/twitterSmallIcom.svg";
import telegramIcon from "../../../assets/img/tgSmallIcon.svg";

const SocialMediaCard = ({
  avatarUrl,
  nickname,
  twitterHandle,
  twitterUrl,
  telegramUrl,
}) => {
  return (
    <div className="inline-block whitespace-nowrap p-3 rounded-lg">
      <div className="flex items-center space-x-4 bg-gray-800 p-3 rounded-lg bg-background-grayPrimary px-[30px] py-[20px] w-[300px] md:w-[450px] h-[96px] md:h-[150px] ">
        <img
          src={avatarUrl}
          alt={`Avatar of ${nickname}`}
          className="w-[80px] md:w-[100px] h-[80px] md:h-[100px] rounded-full"
        />
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex flex-col">
            <span className="font-BenzinBold text-[17px] md:text-[26px] text-neutral-white font-medium">
              {nickname}
            </span>
            <span className="font-montserrat text-[15px] md:text-[23px] text-neutral-white">
              {twitterHandle}
            </span>
          </div>
          <div className="flex gap-2">
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={twitterIcon}
                alt="Twitter"
                className=" w-[13px] md:w-[21px] h-[13px] md:h-[17px]"
              />
            </a>
            <a href={telegramUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={telegramIcon}
                alt="Telegram"
                className="w-[13px] md:w-[21px] h-[13px] md:h-[17px]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaCard;
