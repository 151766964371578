import React from "react";
import bG7 from "../../../assets/img/bg/BG7.png";

const BG7 = () => {
  return (
    <img
      src={bG7}
      alt="coin"
      className="absolute  hidden md:block top-[2900px] right-0"
    />
  );
};

export default BG7;
