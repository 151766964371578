import React from "react";

const stylesByType = {
  default:
    "text-neutral-white font-montserrat border-neutral-orange font-bold border-2 rounded-md ",
  hover: "hover:text-neutral-orange",
};

const DefaultButton = ({ className = "", children, ...props }) => {
  return (
    <>
      <button
        data-tooltip-id="searchButton"
        className={`${stylesByType.default} ${
          props.disabled ? "" : stylesByType.hover
        } ${
          stylesByType.focus
        } flex justify-center items-center font-nunitoSans px-3 py-2 text-neutral-white rounded-[5px] ${className}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default DefaultButton;
