import React from "react";
import DefaultButton from "../../UI/DefaultButton/DefaultButton";
import Jupiter from "../../../assets/img/jupiter.png";
import ArrowBottomIcon from "../../../assets/svg/ArrowBottomIcon";
import Dexscreener from "../../../assets/svg/Dexscreener";
import solscan from "../../../assets/img/solscan.svg";

const ButtonsList = () => {
  const [hovered, setHovered] = React.useState(false);

  // Function to scroll to the component with id="buy"
  const handleBuyHere = () => {
    document.getElementById("BuyNCTR").scrollIntoView({ behavior: "smooth" });
  };

  const handleChart = () => {
    window.open(
      "https://dexscreener.com/solana/gbjpjemczthkf4yqzzqurzr9z6sikamqfp8b2v9zneiv",
      "_blank"
    );
  };

  const handleContract = () => {
    window.open(
      "https://solscan.io/token/AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC",
      "_blank"
    );
  };

  const handleRugchecker = () => {
    window.open(
      "https://rugcheck.xyz/tokens/AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC",
      "_blank"
    );
  };

  return (
    <>
      {/* Desctop */}
      <div className="hidden md:flex items-center gap-10">
        <DefaultButton className="w-32 h-12 flex gap-2" onClick={handleChart}>
          {/* <img src={Dexscreener} alt="Dexscreener icon" /> */}
          <span className="w-[25px]">
            <Dexscreener />
          </span>
          Chart
        </DefaultButton>
        <DefaultButton
          className="w-32 h-12 flex gap-2"
          onClick={handleContract}
        >
          <img src={solscan} alt="Jupiter icon" className="w-[25px]" />
          Contract
        </DefaultButton>
        <DefaultButton className="w-32 h-12" onClick={handleRugchecker}>
          RugCheck
        </DefaultButton>
        <DefaultButton
          className="w-56 h-12 flex gap-2"
          onClick={handleBuyHere}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <img src={Jupiter} alt="Jupiter icon" />
          Buy $NCTR
        </DefaultButton>
      </div>
      {/* Mobile */}
      <div className="flex md:hidden flex-col items-center gap-4">
        <DefaultButton className="w-[212px] h-12" onClick={handleRugchecker}>
          RugCheck
        </DefaultButton>
        <div className="flex gap-4">
          <DefaultButton className="w-[98px] h-12" onClick={handleChart}>
            Chart
          </DefaultButton>
          <DefaultButton className="w-[98px] h-12" onClick={handleContract}>
            Contract
          </DefaultButton>
        </div>

        <DefaultButton
          className="w-[212px] h-12"
          onClick={handleBuyHere}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          Buy $NCTR
        </DefaultButton>
      </div>
    </>
  );
};

export default ButtonsList;
