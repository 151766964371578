import React from "react";
import bG9 from "../../../assets/img/bg/BG9.png";

const BG9 = () => {
  return (
    <img
      src={bG9}
      alt="coin"
      className="absolute  hidden md:block top-[3400px] right-0"
    />
  );
};

export default BG9;
