import React, { useEffect, useState } from "react";
import Heading from "../UI/Heading/Heading";
import UtilityCard from "./UtilityCard/UtilityCard";
import { getUtilities } from "../../utils/api/getUtilities";

// const utilities = [
//   {
//     number: 1,
//     title: "Teleport",
//     description:
//       "Teleport — is a tool for NFT communities to create private groups and channels on Telegram with the verification of holders. Teleport periodically checks if their wallets contain the NFTs required for participation in these groups and channels.",
//     url: "https://teleport.nctr.buzz/",
//   },
//   {
//     number: 2,
//     title: "NFT Builder",
//     description:
//       "NFT Builder — is a convenient, beautiful tool that can help anyone generate their NFT collection without any technical knowledge.",
//     url: "https://builder.nctr.buzz/",
//   },
//   {
//     number: 3,
//     title: "Evolution",
//     description:
//       "Evolution​ — is a super convenient and cool NFTs upgrade tool for Lazy Family holders.",
//     url: "https://evolution.nctr.buzz/",
//   },
// ];

const Utilities = () => {
  const [utilities, setUtilities] = useState([]);
  useEffect(() => {
    getUtilities().then((res) => {
      setUtilities(
        res.data.utilitiesCollection.items.sort((a, b) => a.number - b.number)
      );
    });
  }, []);
  return (
    <div
      id="Utilities"
      className="pt-40 min-h-[100vh] relative z-50 flex flex-col items-center md:items-start gap-4 md:gap-10"
    >
      <Heading type="h2_main">UTILITIES</Heading>

      <div className="mt-8 md:mt-24 flex flex-col items-center gap-6 md:gap-20 w-full">
        {utilities.map((utility, index) => (
          <div
            className={`w-full flex ${
              index % 2 === 0 ? "md:justify-start" : "md:justify-end"
            }`}
            key={utility.number}
          >
            <UtilityCard
              number={utility.number}
              title={utility.title}
              description={utility.description}
              url={utility.url}
              className={
                index % 2 === 0
                  ? "items-center lg:items-start"
                  : "items-center lg:items-end"
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Utilities;
