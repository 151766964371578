import React, { useEffect, useRef, useState } from "react";
import Heading from "../UI/Heading/Heading";
import SocialMediaCard from "./SocialMediaCard/SocialMediaCard";
import solstice from "../../assets/img/solstice.png";
import sugar from "../../assets/img/sugar.png";
import affu from "../../assets/img/affu.png";
import joji from "../../assets/img/joji.png";
import iced from "../../assets/img/iced.png";
import solgoodman from "../../assets/img/solgoodman.png";
import sorryover from "../../assets/img/sorryover.png";
import arrow from "../../assets/img/arrowRight.svg";
import { getInfluencers } from "../../utils/api/getInfluencers";

const socialMediaAccounts = [
  {
    nickname: "TheS◎Lstice",
    twitterHandle: "@The__Solstice",
    avatarUrl: solstice,
    twitterUrl: "https://twitter.com/The__Solstice",
    telegramUrl: "https://t.me/solsticesmoonshots",
  },
  {
    nickname: "sugaR",
    twitterHandle: "@suganarium",
    avatarUrl: sugar,
    twitterUrl: "https://twitter.com/suganarium",
    telegramUrl: "https://t.me/sugarydick",
  },
  {
    nickname: "Affu",
    twitterHandle: "@lethal_affu",
    avatarUrl: affu,
    twitterUrl: "https://twitter.com/lethal_affu",
    telegramUrl: "https://t.me/affucalls",
  },
  {
    nickname: "joji",
    twitterHandle: "@metaversejoji",
    avatarUrl: joji,
    twitterUrl: "https://twitter.com/metaversejoji  ",
    telegramUrl: "https://t.me/jojiinnercircle",
  },
  {
    nickname: "Iced",
    twitterHandle: "@IcedKnife",
    avatarUrl: iced,
    twitterUrl: "https://twitter.com/IcedKnife",
    telegramUrl: "https://t.me/houseofdegeneracy",
  },
  {
    nickname: "S◎LGoodMan",
    twitterHandle: "@_SOLGoodMan",
    avatarUrl: solgoodman,
    twitterUrl: "https://twitter.com/_SOLGoodMan",
    telegramUrl: "https://t.me/solgoodmancalls",
  },
  {
    nickname: "sorryover (3,3)",
    twitterHandle: "@sorryover_",
    avatarUrl: sorryover,
    twitterUrl: "https://twitter.com/sorryover_",
    telegramUrl: "https://t.me/sorryover",
  },
];

const Influencers = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [startX, setStartX] = useState();
  const [scrollLeft, setScrollLeft] = useState();
  const [influencers, setInfluencers] = useState([]);

  const scrollableParent = useRef(null);

  useEffect(() => {
    const response = getInfluencers().then((res) => {
      setInfluencers(res.data.influencerCollection.items);
    });
  }, []);

  const handleMouseDown = (e) => {
    setIsScrolling(true);
    setStartX(e.pageX - scrollableParent.current.offsetLeft);
    setScrollLeft(scrollableParent.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isScrolling) return;
    e.preventDefault();
    const x = e.pageX - scrollableParent.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollableParent.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsScrolling(false);
  };

  return (
    <>
      <div
        id="Influencers"
        className="pt-[100px] relative z-20 md:pt-[436px] flex flex-col items-center  gap-4 md:gap-10 pb-[30px] md:pb-[70px]"
      >
        <Heading type="h2_main" className="text-[28px]">
          INFLUENCERS
        </Heading>
      </div>
      <div
        ref={scrollableParent}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseUp}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        className="flex relative z-20 flex-wrap justify-center"
      >
        {influencers.map((account, index) => (
          <SocialMediaCard
            key={index}
            nickname={account.nickname}
            twitterHandle={account.twitterHandle}
            avatarUrl={account.avatarUrl.url}
            twitterUrl={account.twitterUrl}
            telegramUrl={account.telegramUrl}
          />
        ))}
      </div>
    </>
  );
};

export default Influencers;
