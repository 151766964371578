import React from "react";
import DefaultButton from "../../UI/DefaultButton/DefaultButton";
import Heading from "../../UI/Heading/Heading";
import Paragraph from "../../UI/Paragraph/Paragraph";

const UtilityCard = ({ number, title, description, className, url }) => {
  const handleClick = () => {
    window.open(url, "_blank");
  };

  return (
    <div
      className={`rounded-lg bg-background-grayPrimary max-w-[800px] p-10 mb-4 flex flex-col gap-4 md:gap-10 ${className}`}
    >
      <Heading type="h3_white" className="">{`${number}. ${title}`}</Heading>
      <Paragraph type="p1_main" className="">
        {description}
      </Paragraph>
      <DefaultButton onClick={handleClick} className="w-[250px]">
        Learn More
      </DefaultButton>
    </div>
  );
};

export default UtilityCard;
